<template>
  <div class="game-container">
    <FamilyTreeGame :gameInfo="gameInfo" :isSpeakingPage="true" :showTitle="true" :titleLength="`long`"/>
  </div>
</template>

<script>
import FamilyTreeGame from "@/components/Course/GamePage/FamilyTreeGame";
export default {
  data() {
    return {
      gameInfo: {
        bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/tree-lesson-13-2.svg"),
        titleHanzi: " 我家有……口人，……和……",
        titlePinyin: "Wǒ jiā yǒu……kǒu rén, ……hé……",
        // selfImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/wo.svg"),
        // selfPinyin: "wǒ",
        // selfHanzi: "我",
      },
    };
  },
  components: {
    FamilyTreeGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











