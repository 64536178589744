<template>
  <div class="sing-page-container" :class="{ 'layout-border': !isReviewPage }">
    <SingPage
      :singList="singList"
      :songInfo="songInfo"
      :timeStampList="timeStampList"
      :isReviewPage="isReviewPage"
    ></SingPage>
  </div>
</template>
<script>
import SingPage from "@/components/Course/CoursePage/SingPage";

export default {
  props: {
    isReviewPage: {
      type: Boolean,
    },
  },
  components: {
    SingPage,
  },
  data() {
    return {
      songInfo: {
        songUrl: require("@/assets/audio/songs/lesson-13.mp3"),
      },
      timeStampList: [9.4, 15.8, 21.9, 28.8, 38.0],
      singList: {
        lyricsArr: [
          {
            id: 1,
            spelling: "Bàba, māma, bàba hé māma, ",
            chineseWords: "爸爸、妈妈，爸爸和妈妈，",
          },
          {
            id: 2,
            spelling: "gēge, jiějie, gēge hé jiějie, ",
            chineseWords: "哥哥、姐姐，哥哥和姐姐，",
          },
          {
            id: 3,
            spelling: "dìdi, mèimei, dìdi hé mèimei.",
            chineseWords: "弟弟、妹妹，弟弟和妹妹。",
          },
          {
            id: 4,
            spelling: "Wǒ jiā yǒu sì kǒu rén, bàba, māma, mèimei hé wǒ.",
            chineseWords: "我家有四口人，爸爸、妈妈、妹妹和我。",
          },
        ],
      },
    };
  },
};
</script>
<style lang="scss" scoped>
.singpage-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
}
</style>
