<template>
  <div class="game-container">
    <MagnifierGame :optionList="optionList" :bgImg="bgImg" :title="title"/>
  </div>
</template>

<script>
import MagnifierGame from "@/components/Course/GamePage/MagnifierGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G33-magnifying-glass/book-background.svg"),
      optionList: [
        {
          id: 1,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/book-ren-1.svg"),
        },
        {
          id: 2,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/book-ren-2.svg"),
        },
        {
          id: 3,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/book-ren-3.svg"),
        },
        {
          id: 4,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/book-ren-4.svg"),
        },
        {
          id: 5,
          image: require("@/assets/img/16-GAMES/G33-magnifying-glass/book-ren-5.svg"),
        },
      ],
      title:{
        pinyin:"  rén ",
        hanzi:" 人  "
      }
    };
  },
  components: {
    MagnifierGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











