var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "game-container" },
    [
      _c("FamilyTreeGame", {
        attrs: {
          gameInfo: _vm.gameInfo,
          optionsList: _vm.optionsList,
          sentenceList: _vm.sentenceList,
          lessonNum: 13,
          titleLength: `long`,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }