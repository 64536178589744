<template>
  <div class="game-container">
    <FamilyTreeGame
      :gameInfo="gameInfo"
      :optionsList="optionsList"
      :sentenceList="sentenceList"
      :lessonNum="13"
      :titleLength="`long`"
    />
  </div>
</template>

<script>
import FamilyTreeGame from "@/components/Course/GamePage/FamilyTreeGame";
export default {
  data() {
    return {
      gameInfo: {
        bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/background-tree.svg"),
        titleHanzi: " 我家有……口人，……和……",
        titlePinyin: "Wǒ jiā yǒu……kǒu rén,……hé……",
        selfImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/wo.svg"),
        selfPinyin: "wǒ",
        selfHanzi: "我",
      },
      optionsList: [
        {
          pinyin: "jiějie",
          hanzi: "姐姐",
          id: 3,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/jiejie.svg"),
        },
        {
          pinyin: "dìdi",
          hanzi: "弟弟",
          id: 4,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/didi.svg"),
        },

        {
          pinyin: "bàba",
          hanzi: "爸爸",
          id: 1,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/baba.svg"),
        },

        {
          pinyin: "māma",
          hanzi: "妈妈",
          id: 2,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/mama.svg"),
        },
      ],
      sentenceList: [
        {
          id: 1,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/baba.svg"),
          hanzi: "我有一个爸爸。",
          pinyin: "Wǒ yǒu yí ge bàba.",
        },
        {
          id: 2,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/mama.svg"),
          hanzi: "我有一个妈妈。",
          pinyin: "Wǒ yǒu yí ge māma.",
        },
        {
          id: 3,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/jiejie.svg"),
          hanzi: "我有一个姐姐。",
          pinyin: "Wǒ yǒu yí ge jiějie.",
        },
        {
          id: 4,
          bgImg: require("@/assets/img/16-GAMES/G57-tree/lesson-13/didi.svg"),
          hanzi: "我有一个弟弟。",
          pinyin: "Wǒ yǒu yí ge dìdi.",
        },
      ],
    };
  },
  components: {
    FamilyTreeGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>











