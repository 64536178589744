import { render, staticRenderFns } from "./17_PracticePage_five.vue?vue&type=template&id=3ea77bc1&scoped=true"
import script from "./17_PracticePage_five.vue?vue&type=script&lang=js"
export * from "./17_PracticePage_five.vue?vue&type=script&lang=js"
import style0 from "./17_PracticePage_five.vue?vue&type=style&index=0&id=3ea77bc1&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ea77bc1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/nihaokidsNew-front_BmM9/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3ea77bc1')) {
      api.createRecord('3ea77bc1', component.options)
    } else {
      api.reload('3ea77bc1', component.options)
    }
    module.hot.accept("./17_PracticePage_five.vue?vue&type=template&id=3ea77bc1&scoped=true", function () {
      api.rerender('3ea77bc1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Course/Level1/Chapter2/Lesson13/17_PracticePage_five.vue"
export default component.exports